export default {
    customValidations: {
        vat: {
            generic: 'La partita IVA non è valida.',
            wrongSize: 'La lunghezza della partita IVA non è corretta: la partita IVA deve essere lunga esattamente 13 caratteri. Assicurati di inserire il prefisso nazionale ad esempio: IT00000000000',
            wrongCharacter: 'La partita IVA contiene un carattere non valido.',
        },
        libphoneNumber: 'Il numero inserito non ha un formato valido',
        libphoneNumberMobile: 'Il valore inserito deve essere un numero di cellulare valido',
        alphaNumSpaces: 'Puoi inserire solo lettere, numeri, spazi e caratteri accentati',
        alphaSpaces: 'Puoi inserire solo lettere, spazi e caratteri accentati',
        alphaNumSpacesAndSlash: 'Puoi inserire solo lettere, numeri, spazi e /',
        numPositive: 'Puoi inserire solo un numero maggiore o uguale a 0',
        domain: 'Inserisci un dominio di terzo o di quarto livello',
        url: 'Inserisci un url valido',
        linkProtocol: 'Inserisci un url valido',
        atLeastTenCharacters: 'Lunghezza 10 caratteri',
        atLeastOneUpperletter: '1 Lettera maiuscola',
        atLeastOneLowerletter: '1 Lettera minuscola',
        atLeastOneSpecialCharacter: '1 Carattere speciale',
        atLeastOneNumber: '1 Numero',
        startOrEndWithSpace: 'No spazi a inizio/fine',
        timeHhMmSs: 'Il campo {_field_} deve avere una formattazione valida HH:MM:SS',
        customRegex: 'Il campo {_field_} non ha un formato valido',
        maxLength: 'Puoi selezionare al massimo {_value_} {_field_}',
        noSpaces: 'Il campo {_field_} non può contenere spazi vuoti',
        unique: 'Il campo {_field_} deve essere univoco',
        onlyLettersNumbersSpacesDashUnderscore: 'Il campo {_field_} può contenere solo lettere, numeri, spazi, trattini e trattini bassi',
        onlyDashUnderscoreNumberAndLowercaseLettersNoSpecial: 'Il campo {_field_} può contenere solo lettere minuscole non accentate, numeri e trattini bassi',
    },
}
